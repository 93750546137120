import React from 'react'
import VideoGalleryItem from './VideoGalleryItem'

export default function VideoGalleryRender() {

    const baseUrl = 'https://mbp-cdn.nyc3.digitaloceanspaces.com';

    return (
        <div className="album py-5 bg-light">
            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 mb-4">
                    <h3>
                        Año 2024
                    </h3>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-1.png`}
                        videoUrl={`${baseUrl}/2024/1.mp4`}
                        text='REGLA N°1, ASUMIR EL LIDERAZGO - DEMOSTRAR EL COMPROMISO'
                        origin='SalfaCorp S.A.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-2.png`}
                        videoUrl={`${baseUrl}/2024/2.mp4`}
                        text='REGLA N°2, IDENTIFICAR PELIGROS - EVALUAR LOS RIESGOS'
                        origin='Empresa de Mantenciones y Servicios Salfa S.A.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-3.png`}
                        videoUrl={`${baseUrl}/2024/3.mp4`}
                        text='REGLA N°3, DEFINIR METAS - ELABORAR PROGRAMA'
                        origin='Flesan Minería S.A.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-4.png`}
                        videoUrl={`${baseUrl}/2024/4.mp4`}
                        text='REGLA N°3, DEFINIR METAS - ELABORAR PROGRAMA'
                        origin='Transportes Santolaya Ltda.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-5.png`}
                        videoUrl={`${baseUrl}/2024/5.mp4`}
                        text='REGLA N°4, GARANTIZAR UN SISTEMA SEGURO Y SALUDABLE - ESTAR BIEN ORGANIZADO'
                        origin='Puga, Mujica Asociados S.A.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-6.png`}
                        videoUrl={`${baseUrl}/2024/6.mp4`}
                        text='REGLA N°5, VELAR POR LA SEGURIDAD Y SALUD, EN LAS MÁQUINAS - EQUIPOS Y LUGARES DE TRABAJO'
                        origin='Constructora ALM Imagina'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-7.png`}
                        videoUrl={`${baseUrl}/2024/7.mp4`}
                        text='REGLA N° 6, MEJORAR LAS CUALIFICACIONES - DESARROLLAR LAS COMPETENCIAS'
                        origin='SalfaCorp S.A.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2024/p-8.png`}
                        videoUrl={`${baseUrl}/2024/8.mp4`}
                        text='REGLA N°7, INVERTIR EN LAS PERSONAS, MOTIVAR A TRAVÉS DE LA PARTICIPACIÓN'
                        origin='Isiete Constructora.'
                    />
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 my-4">
                    <h3>
                        Año 2023
                    </h3>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <VideoGalleryItem
                        photo={`${baseUrl}/2023/p-1.jpg`}
                        videoUrl={`${baseUrl}/2023/1.mp4`}
                        text='REGLA N°1, ASUMIR EL LIDERAZGO - DEMOSTRAR EL COMPROMISO'
                        origin='Fuchs, Genolla y Silva S.S.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2023/p-2.jpg`}
                        videoUrl={`${baseUrl}/2023/2.mp4`}
                        text='REGLA N°2, IDENTIFICAR PELIGROS -  EVALUAR LOS RIESGOS'
                        origin='Constructora SALFA S.A.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2023/p-3.jpg`}
                        videoUrl={`${baseUrl}/2023/3.mp4`}
                        text='REGLA N°3, DEFINIR METAS - ELABORAR PROGRAMA'
                        origin='FLESAN MINERÍA'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2023/p-4.jpg`}
                        videoUrl={`${baseUrl}/2023/4.mp4`}
                        text='REGLA N°4, GARANTIZAR UN SISTEMA SEGURO Y SALUDABLE - ESTAR BIEN ORGANIZADO'
                        origin='BESALCO CONSTRUCCIONES'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2023/p-5.jpg`}
                        videoUrl={`${baseUrl}/2023/5.mp4`}
                        text='REGLA N°5, VELAR POR LA SEGURIDAD Y SALUD, EN LAS MÁQUINAS - EQUIPOS Y LUGARES DE TRABAJO'
                        origin='Constructora IMAGINA'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2023/p-6.jpg`}
                        videoUrl={`${baseUrl}/2023/6.mp4`}
                        text='REGLA N° 6, MEJORAR LAS CUALIFICACIONES - DESARROLLAR LAS COMPETENCIAS'
                        origin='ISIETE CONSTRUCTORA'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2023/p-7.jpg`}
                        videoUrl={`${baseUrl}/2023/7.mp4`}
                        text='REGLA N°7, INVERTIR EN LAS PERSONAS, MOTIVAR A TRAVÉS DE LA PARTICIPACIÓN'
                        origin='ISIETE CONSTRUCTORA'
                    />
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 my-4">
                    <h3>
                        Año 2022
                    </h3>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-1.jpg`}
                        videoUrl={`${baseUrl}/2023/1.mp4`}
                        text='Regla N° 1, ASUMIR EL LIDERAZGO - DEMOSTRAR EL COMPROMISO'
                        origin='Desarrollos Constructivos Axis S.A'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-2.jpg`}
                        videoUrl={`${baseUrl}/2023/2.mp4`}
                        text='REGLA N°2, IDENTIFICAR PELIGROS -  EVALUAR LOS RIESGOS'
                        origin='Nexxo S.A'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-3.jpg`}
                        videoUrl={`${baseUrl}/2023/3.mp4`}
                        text='REGLA N°3, DEFINIR METAS - ELABORAR PROGRAMA'
                        origin='Isiete Servicios de Ingeniería y Construcción SpA'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-4.jpg`}
                        videoUrl={`${baseUrl}/2023/4.mp4`}
                        text='REGLA N°4, GARANTIZAR UN SISTEMA SEGURO Y SALUDABLE - ESTAR BIEN ORGANIZADO'
                        origin='Isiete Servicios de Ingeniería y Construcción SpA'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-5.jpg`}
                        videoUrl={`${baseUrl}/2023/5.mp4`}
                        text='REGLA N°5, VELAR POR LA SEGURIDAD Y SALUD, EN LAS MÁQUINAS - EQUIPOS Y LUGARES DE TRABAJO'
                        origin='Constructora Gardilcic'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-5.1.jpg`}
                        videoUrl={`${baseUrl}/2023/5.1.mp4`}
                        text='REGLA N° 5.1, VELAR POR LA SEGURIDAD Y SALUD, EN LAS MÁQUINAS - EQUIPOS Y LUGARES DE TRABAJO'
                        origin='Echeverría Izquierdo Montajes Industriales S.A.'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-6.jpg`}
                        videoUrl={`${baseUrl}/2023/6.mp4`}
                        text='REGLA N° 6, MEJORAR LAS CUALIFICACIONES - DESARROLLAR LAS COMPETENCIAS'
                        origin='Pilotes Terratest'
                    />
                    <VideoGalleryItem
                        photo={`${baseUrl}/2022/p-7.jpg`}
                        videoUrl={`${baseUrl}/2023/7.mp4`}
                        text='REGLA N°7, INVERTIR EN LAS PERSONAS, MOTIVAR A TRAVÉS DE LA PARTICIPACIÓN'
                        origin='Constructora El Sauce'
                    />
                </div>
            </div>
        </div>
    )
}
